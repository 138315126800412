<template>
  <div>
    <ek-dialog
      ref="dialog"
      @ok="submitForm"
      @close="resetForm"
      :isEdit="!!classDto.id"
      :title="classDto.id ? 'تعديل صف' : 'اضافة صف'"
      @delete="delClass(classDto.id)"
      @search="setSearch"
      btnText="صف جديد"
      placeholder="ابحث عن صف"
    >
      <template #body>
        <div>
          <validationObserver ref="classForm">
            <b-form>
              <ek-input-text
                v-model="classDto.name"
                name="اسم الصف"
                label="اسم الصف"
                :rules="[
                  {
                    type: 'required',
                    message: 'اسم الصف مطلوب',
                  },
                ]"
                placeholder="اسم الصف مطلوب"
              ></ek-input-text>
              <ek-input-select
                :options="educationLevelList"
                v-model="classDto.educationalLevel"
                name="المرحلة الدراسية"
                label="المرحلة الدراسية"
                :rules="[
                  {
                    type: 'required',
                    message: 'المرحلة الدراسية مطلوبة',
                  },
                ]"
              ></ek-input-select>
              <ek-input-select
                :options="[
                  { name: 'مخصص', value: ['', ''] },
                  ...colorList.map((item, index) => ({
                    name: `${item}`,
                    value: item,
                  })),
                ]"
                v-model="classDto.colors"
                placeholder="اختر او خصص لون معين"
                valueLabel="value"
                name=" اختر لون"
                label="اللون"
                :rules="[
                  {
                    type: 'required',
                    message: 'اللون مطلوب',
                  },
                ]"
              >
                <template #option="value">
                  <div
                    class="
                      d-flex
                      justify-content-between
                      align-items-center
                      w-100
                    "
                  >
                    <div
                      class="
                        w-25
                        d-flex
                        justify-content-between
                        align-items-center
                      "
                    >
                      <div
                        id="color"
                        :style="`background-color: ${
                          value.label.split(',')[0]
                        }`"
                      ></div>
                      <div
                        id="color"
                        :style="`background-color: ${
                          value.label.split(',')[1]
                        }`"
                      ></div>
                    </div>
                    <span> {{ value.label }}</span>
                  </div>
                </template>
              </ek-input-select>
              <b-form-group
                label="اللون الاول"
                label-for="اللون"
                v-if="classDto.colors[0] == ''"
              >
                <b-form-input
                  type="color"
                  name="اللون"
                  v-model="classDto.colors[0]"
                />
              </b-form-group>

              <b-form-group
                label="اللون الثاني"
                label-for="اللون"
                v-if="classDto.colors[1] == ''"
              >
                <b-form-input
                  type="color"
                  name="اللون"
                  v-model="classDto.colors[1]"
                />
              </b-form-group>

              <ek-input-text
                name="اضافة رقم الصف"
                label=" رقم الصف"
                ref="order"
                :value="classesList.length + 1"
                placeholder=" ادخل رقم الصف"
                :rules="[
                  {
                    type: 'required',
                    message: 'اضافة رقم الصف مطلوبة',
                  },
                ]"
              ></ek-input-text>
            </b-form>
          </validationObserver>
        </div>
      </template>
    </ek-dialog>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
export default {
  computed: {
    ...mapState({
      educationLevelList: (state) => state.classes.educationLevelList,
      classDto: (state) => state.classes.classDto,
      isDialogOpen: (state) => state.classes.isDialogOpen,
      colorList: (state) => state.settings.settingDto.colors,
    }),
    ...mapGetters(["classesList"]),
  },
  methods: {
    submitForm() {
      this.$refs.classForm.validate().then((suc) => {
        if (suc) {
          if (this.classDto.id) {
            this.updateClass(this.classDto);
            this.$refs.dialog.close();
          } else {
            this.classDto.order = this.$refs.order.innerVal;
            this.addClass(this.classDto)
              .then((data) => {
                console.log(data);
                this.$refs.dialog.close();
              })
              .catch(() => {});
          }
        }
      });
    },
    delClass(id) {
      this.deleteClass(id);
      this.$refs.dialog.close();
    },
    resetForm() {
      this.$refs.classForm.reset();
      this.$store.commit("SET_CLASS_DTO");
      this.$store.commit("IS_DIALOG_OPEN", false);
    },
    setSearch(query) {
      this.$store.commit("Set_Search_Dto", {
        keys: ["name", "educationalLevel", "order", "colors"],
        query,
      });
    },
    ...mapActions(["addClass", "updateClass", "deleteClass"]),
  },
  watch: {
    isDialogOpen(nv) {
      if (nv) {
        this.$refs.dialog.open();
      }
    },
  },
};
</script>

<style>
#color {
  width: 30px !important;
  height: 30px !important;
}
</style>
